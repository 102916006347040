require('./frontend_bootstrap');
window.Vue = require('vue');
require('./Helpers/Frontend/plugin');
require('./Helpers/Frontend/mixin');

// ===============shopping cart=============
// import store from "./vuex/store";

Vue.component('Login', require('./views/Login/AdminLogin.vue').default);
Vue.component('ProductDetails', require('./components/frontend/ProductDetails.vue').default);
Vue.component('SearchBox', require('./components/frontend/SearchBox.vue').default);

// ===============app===============
window.app = new Vue({
    el: '#app',
    data: {
        innerWidth: window.innerWidth,
        baseurl: laravel.baseurl,
        loader: false,
        spinner: false,
        isActive: false,
    },
    methods: {},
    mounted() { },
    created() { }
});
