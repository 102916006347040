<template>
  <div class="login-box">
    <FlashMessage></FlashMessage>
    <div class="login-logo">
      <a href="javascript:void(0)"> <b>Let's Go</b> </a>
    </div>
    <!-- /.login-logo -->
    <div class="login-box-body">
      <p class="login-box-msg">Welcome back!!</p>

      <slot v-if="!$root.spinner">
        <form v-on:submit.prevent="submit" method="post">
          <div
            class="form-group has-feedback"
            :class="{
              'has-error': validation.hasError('data.email'),
              'has-success': data.email,
            }"
          >
            <input
              type="email"
              v-model="data.email"
              class="form-control"
              placeholder="Email"
            />
            <span
              class="glyphicon glyphicon-envelope form-control-feedback"
            ></span>
            <span class="help-block">{{
              validation.firstError("data.email")
            }}</span>
          </div>
          <div
            class="form-group has-feedback"
            :class="{
              'has-error': validation.hasError('data.password'),
              'has-success': data.password,
            }"
          >
            <input
              type="password"
              v-model="data.password"
              class="form-control"
              placeholder="Password"
            />
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
            <span class="help-block">{{
              validation.firstError("data.password")
            }}</span>
          </div>
          <div class="row flex justify-content-center">
            <!-- /.col -->
            <div class="col-xs-4 flex justify-content-center">
              <button type="submit" class="btn btn-primary btn-block btn-flat">
                Sign In
              </button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </slot>
      <Spinner v-if="$root.spinner" />
    </div>
    <!-- /.login-box-body -->
  </div>
</template>

<script>
import Spinner from "./../../components/elements/Spinner";

export default {
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
    };
  },
  components: { Spinner },
  methods: {
    submit() {
      this.$validate().then((res) => {
        const error = this.validation.countErrors();
        if (res) {
          this.$root.spinner = true;
          axios
            .post("/admin/loginme", this.data)
            .then((res) => {
              if (res.status == 200 && res.data.id) {
                Admin.login(res.data);
                this.notification(res.data.message, "success");
                window.location = this.$root.baseurl + "/admin/dashboard";
              } else {
                this.$root.spinner = false;
                this.notification(res.data.message, "error");
              }
            })
            .catch((error) => console.log(error));
        }
      });
    },
  },
  created() {
    $(".body").removeClass("hold-transition skin-blue sidebar-mini");
    $(".body").addClass("hold-transition login-page");
  },

  // ================== validation rule for form ==================
  validators: {
    "data.email": function (value = null) {
      return Validator.value(value).required("Email is required");
    },
    "data.password": function (value = null) {
      return Validator.value(value)
        .required("Password is required")
        .minLength(6);
    },
  },
};
</script>


<style>
._vue-flash-msg-body {
  width: 25% !important;
  z-index: 9999999;
}
._vue-flash-msg-body__content h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
  font-size: 1.1rem;
}
._vue-flash-msg-body ._vue-flash-msg-body__icon {
  background-color: #fff0 !important;
}
</style>