<template>
  <div class="header_middle">
    <div class="input-group theme_search_field">
      <input
        v-model="search_key"
        type="text"
        class="form-control"
        placeholder="What are you looking for ?"
      />
      <div class="input-group-append">
        <button class="btn" type="button" id="button-addon1">
          <i class="ti-search"></i>
        </button>
      </div>

      <slot v-if="popup">
        <div
          v-if="products && Object.keys(products).length > 0"
          class="search-box"
        >
          <i class="fa fa-times" @click="close"></i>
          <a
            v-for="(pro, index) in products"
            :key="index"
            class="row pro text-black"
            :href="$root.baseurl + '/product-details/' + pro.slug"
          >
            <div class="col-xl-2 text-center">
              <img :src="pro.image" style="height: 60px; width: 60px" alt="" />
            </div>
            <div class="col-10">
              {{ pro.pro_name }}
            </div>
          </a>
        </div>
        <div class="search-box" v-if="noData">
          <i class="fa fa-times" @click="close"></i>
          <div style="text-align: center; padding-top: 50px">
            No products found
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popup: false,
      noData: false,
      search_key: "",
      products: [],
    };
  },
  watch: {
    search_key: function () {
      if (this.search_key) {
        this.popup = true;
        axios
          .get("/get-search-products", { params: { key: this.search_key } })
          .then((res) => (this.products = res.data))
          .catch((error) => console.log(""))
          .then((alw) => {
            if (Object.keys(this.products).length == 0) {
              setTimeout(() => (this.noData = true), 400);
            }
          });
      }
    },
  },
  methods: {
    close() {
      this.search_key = "";
      this.products = [];
      this.popup = false;
    },
  },
};
</script>

<style scoped>
.search-box {
  background: #ffffff;
  width: 100%;
  height: 450px;
  overflow: scroll;
  z-index: 5000;
}
.search-box .pro {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.search-box .fa-times {
  cursor: pointer;
  padding-left: 10px;
}
</style>